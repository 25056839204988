import { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Box, Typography } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'
import theme, { SECONDARY_COLOR, SERVICE_COLOR, COLOR } from 'src/styles/theme'
import { rem } from 'src/common/utils/css/index'
import CardTrail from 'src/common/components/card/trail/Trail'
import CardPlace from 'src/common/components/card/place/Place'
import CardEvent from 'src/common/components/card/event/Event'
import CardBlogAndNews from 'src/common/components/card/blog-and-news/BlogAndNews'
import ArrowLeft from 'src/components/icons/ArrowLeft'
import ArrowRight from 'src/components/icons/ArrowRight'
import { DAYS } from 'src/common/constants'
import { CARD_VARIANT } from 'src/common/components/card/constants'
import Button from 'src/common/components/button/Button'
import FloatingActionButton from 'src/common/components/floating-action-button/FloatingActionButton'
import { useLinkClickHandler } from 'src/common/utils/hooks/useLinkClickHandler'
import isUrlExternal from 'src/common/utils/js/isUrlExternal'
import { ButtonExternalLink } from 'src/common/components/externalSiteIcon'

const useStyles = makeStyles(theme)((defaultTheme, props) => {
  const { background_color, cards_position } = props
  return {
    container: {
      backgroundColor: COLOR.LIGHT_BACKGROUND,
    },
    wrapper: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: rem(852),
      Width: '100%',
      [theme.breakpoints.up('md')]: {
        minHeight: rem(901),
        minWidth: rem(720),
      },
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
        minHeight: rem(640),
        minWidth: rem(1020),
      },
      [theme.breakpoints.up('xl')]: {
        minHeight: rem(754),
        minWidth: rem(1440),
      },
    },
    cardWrapper: {
      overflow: 'hidden',
      backgroundColor:
        background_color === 'baker_beach'
          ? COLOR.BAKER_BEACH_WHITE
          : SECONDARY_COLOR.MAIN[80],
      order: 2,
      marginTop: rem(40),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '88.88%',
        minHeight: rem(512),
      },
      [theme.breakpoints.up('lg')]: {
        order: cards_position === 'left' ? 1 : 2,
        width: '61.71%',
        minHeight: rem(512),
        marginTop: 0,
      },
      [theme.breakpoints.up('xl')]: {
        order: cards_position === 'left' ? 1 : 2,
        padding:
          cards_position === 'left'
            ? `${rem(56)} ${rem(56)} ${rem(56)} ${rem(156)}`
            : `${rem(56)} ${rem(156)} ${rem(56)} ${rem(56)}`,
        width: '54.57%',
        minHeight: rem(544),
      },
    },
    cardBlock: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      gap: rem(24),
      transition: 'all .5s',
      padding: `${rem(40)} ${rem(40)} ${rem(0)} ${rem(24)}`,
      [theme.breakpoints.up('md')]: {
        flexDirection: 'column',
        padding: rem(40),
      },
      [theme.breakpoints.up('xl')]: {
        padding: 0,
      },
    },
    contentBlock: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      padding: `0 ${rem(24)}`,
      gap: rem(16),
      order: 1,
      paddingTop: rem(40),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        padding: `0`,
        paddingTop: rem(64),
        width: '88.88%',
      },
      [theme.breakpoints.up('lg')]: {
        padding: `0 ${rem(40)}`,
        width: '50%',
        minHeight: rem(512),
        order: cards_position === 'left' ? 2 : 1,
      },
      [theme.breakpoints.up('xl')]: {
        order: cards_position === 'left' ? 2 : 1,
        padding:
          cards_position === 'left'
            ? `${rem(56)} ${rem(156)} ${rem(56)} ${rem(56)}`
            : `${rem(56)} ${rem(56)} ${rem(56)} ${rem(156)}`,
        width: '50%',
        minHeight: rem(544),
      },
    },
    headingText: {
      color: theme.palette.primary.dark,
    },
    description: {
      ...theme.typography.body.default,
      [theme.breakpoints.up('md')]: {
        width: rem(356),
      },
      [theme.breakpoints.up('lg')]: {
        width: '100%',
      },
    },
    cardDiv: {
      minWidth: '85%',
      [theme.breakpoints.up('md')]: {
        width: 'inherit',
      },
    },
    carouselButtons: {
      display: 'flex',
      flexDirection: 'row',
      gap: rem('16'),
      justifyContent: 'flex-end',
      padding: rem(24),
    },
  }
})

export default function CardsWithCta(props) {
  const { data } = props
  let touchstartX
  let touchendX
  let touchstartY
  let touchendY
  // guards
  if (!data || Object.keys(data).length === 0) {
    return null
  }

  const { card_with_cta, section_id } = data
  const {
    background_color,
    cards_position,
    select_card,
    place_type,
    cta,
    subheading,
    heading,
    select_content_type,
    button_style,
  } = card_with_cta || {}
  const { classes } = useStyles({
    background_color,
    cards_position,
  })
  const smUp = useMediaQuery(() => theme.breakpoints.up('md'))
  const [prevKeyActive, setPrevKeyActive] = useState(false)
  const [nextKeyActive, setNextKeyActive] = useState(true)
  const [leftMargin, setLeftMargin] = useState(0)
  const handleClick = (direction) => {
    if (direction === 'right') {
      if (nextKeyActive) {
        setLeftMargin(leftMargin - 70)
        setNextKeyActive(!nextKeyActive)
        setPrevKeyActive(!prevKeyActive)
      }
    }
    if (direction === 'left') {
      if (prevKeyActive) {
        setLeftMargin(leftMargin + 70)
        setNextKeyActive(!nextKeyActive)
        setPrevKeyActive(!prevKeyActive)
      }
    }
  }
  if (select_card.length < 1) return null

  function handleGesture() {
    if (
      touchendX < touchstartX &&
      touchstartX - touchendX > 80 &&
      Math.abs(touchstartY - touchendY) < 20
    ) {
      if (nextKeyActive) handleClick('right')
    }
    if (
      touchendX > touchstartX &&
      touchendX - touchstartX > 80 &&
      Math.abs(touchendY - touchstartY) < 20
    ) {
      if (prevKeyActive) handleClick('left')
    }
  }

  const getDateFromData = (date, type) => {
    const dateFromData = new Date(date * 1000)
    if (type === 'year') {
      return dateFromData.getFullYear()
    }
    if (type === 'day') {
      return dateFromData.getDate()
    }
    if (type === 'month') {
      return dateFromData.toLocaleString('en-US', { month: 'short' })
    }
    if (type === 'dayName') {
      return DAYS[dateFromData.getDay()]
    }

    return ''
  }

  const linkClickHandler = useLinkClickHandler()

  return (
    <div
      className={`module ${classes.container}`}
      id={section_id}
      data-id="section"
    >
      <div className={classes.wrapper}>
        <div className={classes.cardWrapper}>
          <div
            style={{ marginLeft: smUp ? '0' : `${leftMargin}%` }}
            className={classes.cardBlock}
          >
            {select_card &&
              select_card.length > 0 &&
              select_card.map((card, index) => (
                <div
                  onTouchStart={(e) => {
                    touchstartX = e.changedTouches[0].screenX
                    touchstartY = e.changedTouches[0].screenY
                  }}
                  onTouchEnd={(e) => {
                    touchendX = e.changedTouches[0].screenX
                    touchendY = e.changedTouches[0].screenY
                    if (!smUp) handleGesture()
                  }}
                  key={`cardWithCTA${index}`}
                  className={classes.cardDiv}
                >
                  {select_content_type === 'places' &&
                    place_type === 'trail' && (
                      <CardTrail
                        cardData={{
                          title: card.Heading,
                          description: card.Sub_heading,
                          trail: {
                            length: `${card.trail_length_mile} mile (${card.trail_length_km}km)`,
                            activityLevel: card.activity_level,
                          },
                          image: {
                            url: card?.image?.url,
                            alt: card?.image?.alt,
                          },
                          link: {
                            title: card.default_text,
                            url: card.card_url,
                            target: '0',
                          },
                        }}
                        variant={
                          smUp ? CARD_VARIANT.HORIZONTAL : CARD_VARIANT.VERTICAL
                        }
                        gaTag={`cardsidebyside_${cards_position}`}
                      />
                    )}
                  {select_content_type === 'places' &&
                    (place_type === 'site' ||
                      place_type === 'business' ||
                      place_type === 'restaurant' ||
                      place_type === 'region' ||
                      place_type === 'venue' ||
                      place_type === 'commercial') && (
                      <CardPlace
                        cardData={{
                          title: card.Heading,
                          description: card.Sub_heading,
                          image: {
                            url: card?.image?.url,
                            alt: card?.image?.alt,
                          },
                          link: {
                            title: card.default_text
                              ? card.default_text
                              : 'learn more',
                            url: card.card_url,
                            target: '0',
                          },
                        }}
                        variant={
                          smUp ? CARD_VARIANT.HORIZONTAL : CARD_VARIANT.VERTICAL
                        }
                        gaTag={`cardsidebyside_${cards_position}`}
                      />
                    )}
                  {(select_content_type === 'press' ||
                    select_content_type === 'post') && (
                    <CardBlogAndNews
                      cardData={{
                        date: {
                          year: getDateFromData(card.post_date, 'year'),
                          day: getDateFromData(card.post_date, 'day'),
                          month: getDateFromData(card.post_date, 'month'),
                        },
                        title: card.Heading,
                        description: card.Sub_heading,
                        image: {
                          url: card?.image?.url,
                          alt: card?.image?.alt,
                        },
                        link: {
                          title: card.default_text
                            ? card.default_text
                            : 'learn more',
                          url: card.card_url,
                          target: '0',
                        },
                      }}
                      variant={
                        smUp ? CARD_VARIANT.HORIZONTAL : CARD_VARIANT.VERTICAL
                      }
                      gaTag={`cardsidebyside_${cards_position}`}
                    />
                  )}
                  {(select_content_type === 'page' ||
                    select_content_type === 'itineraries') && (
                    <CardPlace
                      cardData={{
                        title: card.Heading,
                        description: card.Sub_heading,
                        image: {
                          url: card?.image?.url,
                          alt: card?.image?.alt,
                        },
                        link: {
                          title: card.default_text
                            ? card.default_text
                            : 'learn more',
                          url: card.card_url,
                          target: '0',
                        },
                      }}
                      variant={
                        smUp ? CARD_VARIANT.HORIZONTAL : CARD_VARIANT.VERTICAL
                      }
                      gaTag={`cardsidebyside_${cards_position}`}
                    />
                  )}
                  {select_content_type === 'tribe_events' && (
                    <CardEvent
                      cardData={{
                        title: card?.Heading,
                        description: card?.Sub_heading,
                        image: {
                          url: card?.image?.url,
                          alt: card?.image?.alt,
                        },
                        iconCalender: card?.recurring_event,
                        date: {
                          day: getDateFromData(card?.event_start_date, 'day'),
                          month: getDateFromData(
                            card?.event_start_date,
                            'month'
                          ),
                          weekDay: getDateFromData(
                            card?.event_start_date,
                            'dayName'
                          ),
                          time: card?.event_start_time,
                        },
                        link: {
                          title: card?.default_text
                            ? card?.default_text
                            : 'learn more',
                          url: card?.card_url,
                          target: '0',
                        },
                      }}
                      variant={
                        smUp ? CARD_VARIANT.HORIZONTAL : CARD_VARIANT.VERTICAL
                      }
                      gaTag={`cardsidebyside_${cards_position}`}
                    />
                  )}
                </div>
              ))}
          </div>
          {!smUp && (
            <Box className={classes.carouselButtons}>
              <Box onClick={() => handleClick('left')}>
                <FloatingActionButton
                  title="Previous Card"
                  aria-label="Previous Card"
                  disabled={nextKeyActive}
                  data-testid="prev-button"
                  tabIndex={-1}
                >
                  <ArrowLeft
                    color={prevKeyActive ? COLOR.NEAR_WHITE : undefined}
                  />
                </FloatingActionButton>
              </Box>
              <Box tabIndex={0} onClick={(event) => handleClick('right')}>
                <FloatingActionButton
                  title="Next Card"
                  aria-label="Next Card"
                  disabled={prevKeyActive}
                  data-testid="next-button"
                  tabIndex={-1}
                >
                  <ArrowRight
                    color={
                      !nextKeyActive
                        ? SERVICE_COLOR.DISABLED.PRIMARY
                        : undefined
                    }
                  />
                </FloatingActionButton>
              </Box>
            </Box>
          )}
        </div>
        <div className={classes.contentBlock}>
          <Typography
            data-testid="headingText"
            className={classes.headingText}
            variant="h2"
          >
            {heading}
          </Typography>
          <Typography className={classes.description} variant="body">
            {subheading}
          </Typography>
          {cta && cta.title && (
            <a
              style={{ width: smUp ? '' : '100%' }}
              href={cta.url}
              onClick={(e) => {
                e.preventDefault()
                linkClickHandler(cta)
              }}
              data-ga-location={`cardsidebyside_${
                cards_position === 'left' ? 'right' : 'left'
              }`}
            >
              <Button
                style={{ width: smUp ? '' : '100%' }}
                variant={button_style}
                tabIndex={-1}
                endIcon={
                  isUrlExternal(cta?.url) ? <ButtonExternalLink /> : null
                }
              >
                {cta.title}
              </Button>
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

CardsWithCta.propTypes = {
  data: PropTypes.shape({
    section_title: PropTypes.string,
    section_id: PropTypes.string,
    card_with_cta: PropTypes.shape({
      heading: PropTypes.string,
      subheading: PropTypes.string,
      select_content_type: PropTypes.string,
      place_type: PropTypes.string,
      background_color: PropTypes.string,
      cards_position: PropTypes.string,
      cta: PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }),
      select_card: PropTypes.array,
    }),
  }),
}
